/* Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* Poiret One */
/* Forum */
@import url('https://fonts.googleapis.com/css2?family=Forum&family=Poiret+One&display=swap');


*,*::before,*::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html { 
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-text-stroke: 0.15pt;
}

body {
  margin: 0;
  position: relative;
  background: black;
}


body::before {
  position: fixed;
  content: '';
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background-image: url("./assets/bg-pattern.png");
  opacity: .5;
  z-index: -1;
}

/* body::after {
  position: fixed;
  content: '';
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%);
  opacity: 1;
  z-index: 1;
} */


::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  /* // background-color: rgba(255, 255, 255, 0.705);
  // background-color: rgba(209, 145, 25, .75); */
  background-color: #455e58;
  border-radius: 5px;
  /* // border: 1px solid transparent; */
  border-right: 1px solid transparent;
  background-clip: content-box;
}
